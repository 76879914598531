import { Link } from "gatsby"
import React from "react"
import * as moment from "moment"

export default function StoryCard(props) {
  return (
    <div className="col-md-3">
      <Link to={props.to}>
      <div class="story-card">
        <div className="card-shade"></div>
        <img
          src="https://www.pandasecurity.com/en/mediacenter/src/uploads/2013/11/pandasecurity-facebook-photo-privacy.jpg"
          alt="card"
        />
        
        <div className="card-text">
          <h6>{props.title}</h6>
          <p>{moment(props.date).format('MMMM Do YYYY')}</p>
        </div>
      </div>
      </Link>
    </div>
  )
}
